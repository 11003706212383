import styled from 'styled-components';

interface Props {
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  align?: "flex-start" | "flex-end" | "center" | "stretch" | "baseline",
  justify?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly" | "right",
  wrap?: "wrap" | "nowrap" | "wrap-reverse";
  margin?: string;
  padding?: string;
  gap?: string;
  height?: string;
}

const Flex = styled.div`
  display: flex;
  flex-direction: ${(props: Props) => props.direction || "row"};
  align-items: ${(props: Props) => props.align || "stretch"};
  justify-content: ${(props: Props) => props.justify || "flex-start"};
  margin: ${(props: Props) => props.margin || "0"};
  padding: ${(props: Props) => props.padding || "0"};
  flex-wrap: ${(props: Props) => props.wrap || "nowrap"};
  gap: ${(props: Props) => props.gap || "0"};
  ${(props: Props) => `height: ${props.height};`}
`

export default Flex;
