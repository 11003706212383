import React from "react";
import { isEmpty } from "lodash";

// typings
import { AxiosError } from "axios";

// components
import TubeLoader from "./TubeLoader";
import LoaderErrorContainer from "../Containers/LoaderErrorContainer";
import ErrorMessage from "../Typography/ErrorMessage";

interface Props {
  loading: boolean;
  error?: AxiosError | {};
}

const LoadingError = ({ loading, error }: Props): JSX.Element => {
  return (
    <>
      {loading &&
        <LoaderErrorContainer>
          <h1>Cargando...</h1>
          <TubeLoader style={{ marginTop: 40 }} />
        </LoaderErrorContainer>
      }
      {error && !isEmpty(error) &&
        <LoaderErrorContainer>
          <ErrorMessage>
            Lo sentimos, ha ocurrido un error, por favor intenta nuevamente más tarde. Si el error persiste, por favor comunícate con el equipo de desarrollo.
            <br />
            {error.response?.status}: {JSON.stringify(error.response?.data)}
          </ErrorMessage>
        </LoaderErrorContainer>
      }
    </>
  )
}

export default LoadingError;
