import React, { useEffect, useState } from "react";
import { Button, createTheme } from "@material-ui/core";
import { globalHistory } from "@reach/router";

import { ThemeProvider } from "@material-ui/core";
import Navbar from "../nav/Navbar";
import FilterStore from "../Stores/FilterStore";
import LoadingError from "../Loaders/LoadingError";
import {
  Content,
  Logo,
  MainLayoutWrap,
  NavCell,
  Sidebar,
} from "../../styles/Layout/MainLayout";
import {
  MdDashboard,
  MdDataUsage,
  MdReport,
  MdSupervisorAccount,
  MdShoppingCart,
  MdMedicalServices,
  MdDirectionsCar,
  MdMap,
} from "react-icons/md";
import { IoCloudOffline } from "react-icons/io5";
import { ImPriceTags } from "react-icons/im";
import { TiArrowRepeat } from "react-icons/ti";
import { FiPackage } from "react-icons/fi";
import {
  FaBookMedical,
  FaClinicMedical,
  FaUser,
  FaWpforms,
} from "react-icons/fa";
import Auth from "../../utils/auth/auth";
import authenticationService from "../../utils/api/v1/authenticationService";
import { Link, navigate } from "gatsby";
import Flex from "./Flex";

const auth = new Auth();

const theme = createTheme({
  palette: {
    primary: {
      main: "#039Be5",
    },
    secondary: {
      main: "#FF6D70",
    },
  },
});

const navs = [
  {
    path: "/offline-appointment/assisted-schedule",
    name: "Agendamiento asistido",
    icon: <IoCloudOffline style={{ color: "var(--primary)" }} />,
  },
];

const MainLayout = (props) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [showLayout, setShowLayout] = useState(
    window.location.pathname !== "/"
  );
  const [active, setActive] = useState<string>("Dashboard");

  useEffect(() => {
    return globalHistory.listen((routeChange) => {
      if (routeChange.action === "PUSH") {
        setShowLayout(routeChange.location.pathname !== "/");
      }
    });
  }, []);

  useEffect(() => {
    const fetchRefreshToken = async (refreshToken: string) => {
      setLoading(true);
      try {
        const res = await authenticationService.refreshAccessToken(
          refreshToken
        );
        const userData = res.data["https://api.saludando.cl/jwt/claims"];
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("token", res.data.access_token);
        localStorage.setItem("refresh_token", res.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(res.data));
        if (userData) {
          localStorage.setItem("auth_id", userData["auth_id"]);
        }
      } catch (err) {
        console.log(err);
        auth.logout(() => {});
      } finally {
        setLoading(false);
      }
    };

    const accessToken = auth.getAccessToken();
    const refreshToken = auth.getRefreshToken();
    if (
      !accessToken ||
      !refreshToken ||
      accessToken === "undefined" ||
      refreshToken === "undefined"
    ) {
      setLoading(false);
      auth.logout(() => {});
    } else if (auth.tokenIsExpired()) {
      localStorage.removeItem("token");
      fetchRefreshToken(refreshToken);
    } else {
      setLoading(false);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <link
        rel="stylesheet"
        href="https://unpkg.com/leaflet@1.9.1/dist/leaflet.css"
        integrity="sha256-sA+zWATbFveLLNqWO2gtiw3HL/lh1giY/Inf1BJ0z14="
        crossOrigin=""
      />
      <script
        src="https://unpkg.com/leaflet@1.9.1/dist/leaflet.js"
        integrity="sha256-NDI0K41gVbWqfkkaHj15IzU7PtMoelkzyKp8TOaFQ3s="
        crossOrigin=""
      />
      <FilterStore>
        <>
          {showLayout && auth.isLoggedIn() && <Navbar />}
          <MainLayoutWrap>
            {auth.isLoggedIn() && (
              <Sidebar>
                <Flex align="center" justify="center">
                  <Logo
                    src={`https://provider-logos-2.s3.us-east-1.amazonaws.com/marketplace.png`}
                  />
                </Flex>
                <div style={{ marginTop: 20 }}>
                  {navs.map((item, i) => (
                    <Link
                      to={item.path}
                      style={{ textDecoration: "none" }}
                      key={i}
                    >
                      <NavCell
                        key={i}
                        style={{
                          backgroundColor: active === item.name ? "whitesmoke" : "white",
                        }}
                        onClick={() => {
                          setActive(item.name);
                        }}
                      >
                        {item.icon} <h6>{item.name}</h6>
                      </NavCell>
                    </Link>
                  ))}
                  <NavCell>
                    <Button onClick={() => auth.logout(() => navigate("/"))}>
                      Cerrar Sesión
                    </Button>
                  </NavCell>
                </div>
              </Sidebar>
            )}
            <Content showLayout={showLayout} style={{ overflowX: "auto" }}>
              {loading && <LoadingError loading={loading} error={false} />}
              {!loading && props.children}
            </Content>
          </MainLayoutWrap>
        </>
      </FilterStore>
    </ThemeProvider>
  );
};

export default MainLayout;
