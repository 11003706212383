import "./global.css";
import "normalize.css";
import "react-notifications-component/dist/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom";

import GlobalStyles from "./src/components/GlobalStyles";
import { StylesProvider } from "@material-ui/core/styles";
import MainLayout from "./src/components/Containers/Layout";

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
}

export const wrapRootElement = (props) => {
  return (
    <StylesProvider injectFirst>
      <GlobalStyles />
      <MainLayout>{props.element}</MainLayout>
    </StylesProvider>
  );
};
