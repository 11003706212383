import api from "../api";
import { AxiosResponse } from "axios";

class AuthenticationService {
  login = (authorizeData: any): Promise<AxiosResponse<any>> => {
    return api.post("users/login_v2/", authorizeData);
  };

  refreshAccessToken = (refreshToken: string): Promise<AxiosResponse<any>> => {
    return api.post("users/refresh_access_token/", {
      refresh_token: refreshToken,
    });
  };

  requestRecoverPassword = (email: string): Promise<AxiosResponse<any>> => {
    return api.post("users/request_recover_password/", {
      email,
    });
  };

  verifyCode = (
    email: string,
    verification_code: string
  ): Promise<AxiosResponse<any>> => {
    return api.post("users/verify_code/", { email, verification_code });
  };

  setNewPassword = (
    email: string,
    verification_code: string,
    password_1: string,
    password_2: string
  ): Promise<AxiosResponse<any>> => {
    return api.post("users/set_new_password/", {
      email,
      verification_code,
      password_1,
      password_2,
    });
  };
}

const authenticationService = new AuthenticationService();

export default authenticationService;
