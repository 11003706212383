import { createGlobalStyle } from "styled-components";

import LatoTTF from "../assets/fonts/Lato-Regular.ttf";

const GlobalStyles = createGlobalStyle`

/*
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face{
  font-family: "Lato";
  src: url(${LatoTTF});
}

*{
font-family: "Lato";
};
*/

.notification-container--bottom-center, .notification-container--center, .notification-container--top-center{
  max-width: 100%;
}
:root{
  --lightblue: #81D4F4;
  --clearblue: #E2F4FE;
  --darkblue: #163359;
  --richblue: #1665ec;
  --vibrantblue: #039BE5;
  --sand: #F1E4C5;
  --clearsand: #FAF5EA;
  --purple: #5A39C6;
  --green: #44AF69;
  --red: #FF6D70;
  --red2: #BB342F;
  --pink: #FFDEDB;
  --clearteal: #E0F2F1;
  --primary: #039Be5 !important;
  --hardblue: #0051FF;
  --box-shadow: 0 0 40px rgb(0 0 0 / 10%);
  --whitesmoke: #fcfcfc;

  --yellow: #FFF04B !important;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  color: rgb(72,72,72);

  @media only screen and (min-width: 501px){

  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='33' height='33' viewBox='0 0 90 90'%3E%3Ccircle fill-opacity='0.05' fill='%238a8bbb' cx='45' cy='45' r='5'/%3E%3Cg fill='%23686c88' fill-opacity='0.05'%3E%3Ccircle cx='0' cy='90' r='5'/%3E%3Ccircle cx='90' cy='90' r='5'/%3E%3Ccircle cx='90' cy='0' r='5'/%3E%3Ccircle cx='0' cy='0' r='5'/%3E%3C/g%3E%3C/svg%3E"); */
  }

  ::-webkit-scrollbar {
  display: none;
  }

/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */


}

h1, h2, h3, h4, h5, h6, p{
    margin: 0px;
    color: rgb(72, 72, 72);
}

a:hover{
  text-decoration: unset !important;
}

.swal2-container{
  z-index: 10000000 !important;
}


.css-yk16xz-control, .css-1pahdxg-control{
  height: 50px;
  padding-left: 0px;

  @media only screen and (max-width: 500px){
    padding-left: 30px;
  }
}

.css-26l3qy-menu{
  z-index: 100000 !important;
}

#map {
  width: 100%;
  height: 600px;
}

`;

export default GlobalStyles;
