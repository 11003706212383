import axios from "axios";

const API_URL = process.env.GATSBY_API_URL;
// const API_URL = "https://api-dev.examedi.com";
// const API_URL = "http://localhost:8000";

const api = axios.create({
  baseURL: API_URL,
});

// request header
api.interceptors.request.use(
  (config) => {
    if (localStorage.hasOwnProperty("user")) {
      if (localStorage.getItem("token")) {
        config.headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
      }
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
