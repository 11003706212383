import React from "react";
import styled from "styled-components";
import tubeLoader from "../../assets/animations/loading_tube.gif";

const TubeLoaderIcon = styled.img`
  height: 200px;
  width: 200px;
  object-fit: contain;
`;

interface Props {
  style?: Object;
}

const TubeLoader = (props: Props) => {
  return <TubeLoaderIcon src={tubeLoader} style={props.style} />;
};

export default TubeLoader;
